import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import toast, { Toaster } from "react-hot-toast";
import useActivate from "../../../hooks/useActivate";
import useAuth from "../../../hooks/useAuth";
import { useQueryClient } from "react-query";
import { useState } from "react";
import LoadingGif from "../../shared/LodingGif";

const ActivateDialogue = ({ open, handleClose, Id, url, entity }) => {
  const { auth } = useAuth();
  const activate = useActivate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const handleActivate = async (_id) => {
    setLoading(true);
    const activateUrl = `${url}/activate/${_id}`;
    try {
      const result = await activate(activateUrl, auth.accessToken);
      if (!result.success) {
        toast.error(`error activating ${entity}`);
        //console.log(result);
        //handleClose({type:"deActivate"});
        setLoading(false);
        return;
      }
      toast.success(`${entity} activated successfully`);
      queryClient.invalidateQueries(`${entity}s`);
      handleClose({ type: "activate" });
    } catch (error) {
      console.error(error);
      toast.error(`error activating ${entity}`);
    } finally {
      setLoading(false);
    }
    //
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose({ type: "activate" });
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <Toaster />
        <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <button
            type="button"
            onClick={() => handleClose({ type: "activate" })}
            className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="deleteModal"
          >
            <svg
              aria-hidden="true"
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="flex justify-center p-5">
            <svg
              className="w-12 h-12 font-bold  text-lightgreen dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4"
                d="M5 11.917 9.724 16.5 19 7.5"
              />
            </svg>
          </div>

          <p className="mb-4 text-gray-500 dark:text-gray-300">
            Are you sure you want to Activate?
          </p>
          <div className="flex justify-center items-center space-x-4">
            <button
              data-modal-toggle="deleteModal"
              type="button"
              onClick={() => handleClose({ type: "activate" })}
              className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              No, cancel
            </button>
            <button
              type="submit"
              onClick={() => handleActivate(Id)}
              className="py-2 px-3 text-sm font-medium text-center text-white bg-secondary rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
            >
              {loading ? <LoadingGif /> : " Yes, I'm sure"}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ActivateDialogue;
