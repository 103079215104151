import React from 'react';

const PrivacyPolicy = () => {
    return (
      <div className="pt-32 px-6 text-justify max-w-4xl mx-auto text-base">
        <h1 className="text-5xl text-center  font-bold mb-4">Privacy Policy</h1>
        <p className="mb-4">
          Lifepadi Logistics Limited ("Lifepadi", “we”, “us”, “our”) provides a
          platform for its users to request and purchase items from service
          providers and have it delivered to their doorstep (“Services”). At
          Lifepadi, one of our core values is transparency and we owe you
          ('User') a duty to tell you how we collect, use and share your
          personal information. Personal information means information that
          identifies you and includes information such as your name, email
          address, telephone number as well as any other personal data
          collected. We recommend that you read this Privacy Policy ("Policy")
          carefully to understand your rights and our duties towards managing
          your personal data in addition to our Terms of Use. If you have any
          questions about either of these documents, please send us an email at
          info@Lifepadi.com.
        </p>
        <div>
          <h2 className="text-2xl font-semibold mb-2">
            1. Notification of Changes to Privacy Policy
          </h2>
          <p className="mb-4">
            We are continually improving our methods of communication and adding
            new functionalities and features to our website and existing
            services. Because of these ongoing changes, changes in the law and
            the changing nature of technology, our data protection practices
            will change from time to time. If and when our data protection
            practices change, we will update this Policy to describe our new
            practices and publish them on our website and applications.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-2">
            2. Collecting your personal information
          </h2>
          <p className="mb-4">
            We are committed to informing and limiting the collection of
            essential personal information. We can get your personal information
            when you:
          </p>
          <ul className="mb-4 list-disc list-inside">
            <li>Buy a product or service from us.</li>
            <li>
              Register for a specific product or service (such as registering
              your name, phone number, email address and physical address
              details for any of our services).
            </li>
            <li>Subscribe to newsletters or other services from us.</li>
            <li>
              Ask us for more information about a product or service or contact
              us with a question or complaint.
            </li>
            <li>Take part in a promo or survey.</li>
            <li>Use our website and other services.</li>
            <li>Visit or browse our website or other platforms.</li>
            <li>
              With your permission or consent and/or as permitted by law, we may
              also collect information about you from other organizations or
              third parties if this is appropriate and allowed by law. These
              include fraud-prevention agencies, business directories, and
              credit reference agencies.
            </li>
          </ul>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">
            3. Understanding what you want (the use of cookies)
          </h1>
          <p>
            We might use cookies (small text files in your browser) and other
            techniques such as web beacons (small, clear picture files that
            follow your movements on our website). These collect information
            that makes the website remember you and tells us how you use our
            website, web-related products, and services.
          </p>
          <p>
            This, in turn, helps us make our website relevant to your interests
            and needs. They also help us find information once you have logged
            in or help us link your browsing information to you and your
            personal information, for example, when you choose to register for a
            service. We may use a persistent cookie (that stays linked to your
            browser) to record your details so we can recognise you if you visit
            our website again.
          </p>
          <p>
            Cookies by themselves cannot be used to discover your identity.
            Cookies do not damage your computer. You can set your browser to
            notify you when you receive cookies. This enables you to decide if
            you want to accept it or not. If you choose not to accept cookies
            from our website, this may limit its functionalities or performance.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">
            4. Use and analysis of your personal information
          </h1>
          <p>We may use and analyse your information to:</p>
          <ul>
            <li>
              Provide delivery Services and facilitate the listing of service
              providers around you.
            </li>
            <li>
              Keep you informed generally about new products and services
              (unless you choose not to receive our marketing messages).
            </li>
            <li>
              Understand how you use our network, products, and services to
              develop more interesting and relevant products and services and
              personalise the products and services we offer you.
            </li>
            <li>Comply with all applicable laws and regulations.</li>
            <li>
              Develop, improve, enhance, modify, add to and further develop our
              services.
            </li>
            <li>
              Authenticate users, verify identity, and ensure that the data
              provided is credible.
            </li>
            <li>Prevent and detect fraud and illegal activities.</li>
            <li>
              Provide customer support services, including responding to your
              enquiries or complaints.
            </li>
            <li>
              Conduct customer surveys and research & development activities.
            </li>
            <li>
              Fulfil our obligations or claim our rights in legal proceedings.
            </li>
            <li>
              Process and facilitate payment for services provided on our
              platform.
            </li>
            <li>
              Respond to any questions or concerns you may have about using our
              products and services.
            </li>
            <li>
              Let you know about other companies' products and services we think
              may interest you (including offers and discounts we’ve specially
              negotiated for our customers).
            </li>
            <li>
              Use or disclose it as otherwise authorised or permitted by law.
            </li>
            <li>
              Contact you at any time through your provided telephone number,
              email address or other contact.
            </li>
            <li>
              Provide aggregated reports to our vendors to enable them to serve
              you better.
            </li>
            <li>For any other purpose with your consent.</li>
          </ul>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">5. Consent</h1>
          <p>
            You accept this Notice when you sign our Consent Form here or click
            on the “accept” button to opt-in and use any of our Platforms and
            Services or visit any of our offices for official or non-official
            purposes. You also consent to the collection and processing of your
            personal information (which includes but is not limited to your
            name, date of birth, email address, address, telephone number, etc),
            your order details, order history, and any other information for the
            purpose of providing the Lifepadi Services. If you decide to opt out
            of any of the Lifepadi Services or withdraw consent for the
            processing of your personal information, you can do so by reaching
            out to us at info@Lifepadi.com or filling out our Data Subject
            Request Form. This Notice governs the use of the Lifepadi Services
            and if you are not comfortable with us processing your data, you may
            not use our Services.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">
            6. Lawful Basis for Processing Data
          </h1>
          <p>
            The lawful basis for processing your data is the performance of our
            contractual obligations to you. The personal data we collect and
            process in order to fulfil our contractual obligations to you
            include but are not limited to your name, address, and phone numbers
            for the fulfilment of your orders and completion of your deliveries.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">
            7. How we collect your personal information
          </h1>
          <p>
            We collect information directly from you as well as automatically
            through your use of our Services, and in some cases, from third
            parties subject to your authorisation.
          </p>
          <p>
            When you fill out a form, participate in a contest or promotion,
            request customer service, or otherwise communicate with us, we need
            your information to understand your needs and to provide relevant
            services to you.
          </p>
          <p>
            We may also receive information about you from other sources and
            combine that information with the information we collect directly.
            Examples of information we may receive from other sources include
            updated delivery or payment information used to correct our records,
            purchase or redemption information, as well as customer support and
            enrollment information.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">
            8. The Personal Information we collect
          </h1>
          <p>
            The information we collect about you depends on the services you use
            and subscribe to. We collect only the information essential for you
            to create an account with Lifepadi and use our Services. The
            information we collect includes:
          </p>
          <ul>
            <li>
              Your personal information in relation to your account such as your
              name, age, date of birth, gender, physical address, account
              information, email address and phone number. We may also collect
              the name, physical address, phone number, email address and other
              information about third parties related to you or their use of our
              services if you provide that information to us. We shall process
              this information on the assumption that you have obtained the
              prior consent of the third parties and we shall not be liable for
              any breach of data information in this regard.
            </li>
            <li>
              Details relating to when and where you request our Services and
              the details of any orders made or received by you in relation to
              our Services, including your geographical location.
            </li>
            <li>
              Details of the device on which any of our Platforms or
              applications have been installed or accessed, which may include
              your operating system and versions, unique device identifiers,
              serial numbers, mobile network and Wi-Fi information.
            </li>
            <li>
              Personal information collected and held via financial or
              third-party payment systems about the payment mechanism or method
              that you might use (including credit card details) and the
              payments you may make for our Services or other goods or services
              you request or receive that are related to the Services we
              provide.
            </li>
            <li>
              Our connection to other individuals whose personal information we
              may collect or hold, for example, family members, friends, or
              referrals.
            </li>
            <li>
              Details about your participation in any loyalty programs linked or
              available through us or any of our business partners or
              affiliates.
            </li>
            <li>
              If you use social media platforms to interact with us or other
              services provided by third parties through Lifepadi, any
              information that you allow the social media platform and/or the
              other third-party site to share with us.
            </li>
            <li>Any address that you may use, for delivery services.</li>
            <li>
              If you respond to optional survey requests, demographic
              information including age, marriage status, ethnicity, profession
              and income.
            </li>
          </ul>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">
            9. Third party technologies
          </h1>
          <p>
            Occasionally, at our discretion, we may include or offer third-party
            products or services on our Platform. These third-party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            Platform and welcome any feedback about these sites.
          </p>
          <p>
            We may also use third-party websites such as Facebook and Twitter
            for interactive information sharing and to connect with Users. We
            recommend that you read any terms and conditions (including any
            privacy statements or policies) that apply to any third-party web
            service relating to the handling and management of your personal
            information as they may use your personal information in ways and
            for purposes that are different from the way Lifepadi uses and
            processes your personal information.
          </p>
          <p>
            We encourage you to keep your personal information confidential by
            contacting them through direct or private messages, email or the
            customer service hotline.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">10. Location Services</h1>
          <p>
            Before you can use our Platform for the first time, we will ask you
            to choose whether to allow Lifepadi access to your location and in
            what circumstances. Location services must be enabled for us to
            provide our Services to you. You may change these settings at any
            time on your device. Once you allow us access your location, we will
            obtain your location through IP address, GPS, and other sensors that
            provide necessary information for the provision of our Services.
          </p>
          <p>
            We may collect Wi-Fi metadata from your device including whether
            your device is Wi-Fi and GPS enabled and connected to Wi-Fi, the
            Media Access Control (MAC) address of your device, the Set Service
            Identifier (SSID) and signal level of Wi-Fi networks within the
            range of your device, the GPS location of your device in relation to
            Wi-Fi networks in the range of your device, and the time the
            metadata was collected. We use the information primarily so that we
            can recommend service providers closest to you.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">11. Communication</h1>
          <p>
            The Lifepadi Platform allows you to contact us via call or message
            anonymously once the product has been purchased from the chosen
            service provider to facilitate the provision of delivery services.
            If you use this functionality, Lifepadi will record all
            communications (voice and text) made and received through the
            Lifepadi Platform for safety, monitoring and quality assurance
            purposes, fraud prevention, and analytical purposes to improve our
            services, including our customer service, policies and procedures
            and/or to investigate and deal with disputes and complaints. By
            using this functionality, you consent to Lifepadi recording and
            using these communications as stated in this Notice.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">12. Surveys</h1>
          <p>
            We may request that you complete a survey to allow us to have a
            better understanding of our Services and to improve them. If you
            choose to participate in any optional survey, we may collect
            demographic information about you, including age, marriage status,
            ethnicity, profession and income, which could include your sensitive
            information. We may also collect information about the way you use
            our Services, and your experiences with it. By participating in any
            survey, you consent to our collection and use of the information you
            provide in accordance with this privacy notice.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">
            13. Disclosures of your personal information
          </h1>
          <p>
            13.1. Lifepadi will not sell or rent your information to third
            parties. However, we may disclose your information to:
          </p>
          <ul>
            <li>any of our group companies or subsidiaries;</li>
            <li>
              agents, vendors or partners who collaborate with us to provide the
              services to you.
            </li>
            <li>
              Companies who are engaged to perform services for and on behalf of
              Lifepadi.
            </li>
            <li>
              Where applicable, credit reference, fraud prevention or business
              scoring agencies or other credit scoring agencies.
            </li>
            <li>
              Debt collection agencies or other debt recovery organisations.
            </li>
            <li>
              Law enforcement agencies, regulatory organisations, courts or
              other public authorities if we must, or are authorised by law.
            </li>
            <li>
              Protect us if it is reasonable against fraud, defending our rights
              or property, or to protect the interests of our customers.
            </li>
            <li>Investors, financial advisers or auditors.</li>
            <li>
              Subject to any limitations in your information permission and
              settings, we may share your information for our everyday business
              purposes, such as processing your orders and delivery purposes;
            </li>
            <li>
              We may share, transfer or disclose the information in our
              databases and server logs to comply with a legal requirement, for
              the administration of justice, interacting with anti-fraud
              databases, to protect your vital interest, to protect the security
              or integrity of our databases or our website, to take precautions
              against legal liability. We will inform you of any such transfer
              or disclosure if we are required to do so by law.
            </li>
          </ul>
          <p>
            13.2. Lifepadi will comply with its obligations under the relevant
            data protection law in relation to any such disclosures, including
            using reasonable commercial efforts to ensure that such third
            parties only use your personal information as reasonably required
            for the purpose of disclosure and in a manner consistent with the
            privacy legislation. For example, (where commercially practicable)
            by including suitable privacy and confidentiality clauses in our
            agreement with those third parties.
          </p>
          <p>
            13.3. Your privacy and information permissions and settings can be
            updated at any time on the Lifepadi mobile application when you are
            logged into your account.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">14. Disclaimer</h1>
          <p>
            Lifepadi has no prior relationship or representation with the
            service providers whose services are listed on Lifepadi’s Platform.
            Lifepadi is solely responsible for the purchase of the customer’s
            order and the delivery of the order to the customer’s provided
            address upon confirmation of payment.
          </p>
          <p>
            Lifepadi will not be responsible for any breach in the
            representations made by the service provider concerning any order
            made by you and does not guarantee that the representations will be
            fit for purpose, true and accurate.
          </p>
        </div>
        <div>
          <h1 className="text-2xl font-semibold mb-2">
            15. Storage and Security
          </h1>
          <p>
            We protect your information using physical, technical, and
            administrative security measures to reduce the risks of loss,
            misuse, and unauthorised access. Personal information is collected
            on servers in electronic databases which are located in Ireland and
            managed by our related entities and/or service providers. We will
            ensure that your information is protected and that they only process
            your information in the way we have authorised them to. These
            organisations will not be entitled to use your personal information
            for their own purposes.
          </p>
          <p>
            We have put in place suitable physical, and electronic
            administrative procedures and measures to safeguard and protect the
            information about you that we collect, and to reduce the risks of
            loss, misuse, disclosure, alteration and unauthorised access to your
            personal information within our custody. Personal information and
            associated data stored on servers are encrypted and stored through
            data isolation technology.
          </p>
          <p>
            We limit access to personal information to individuals within our
            employ or that of our related entities or contracted service
            providers who we believe reasonably need access to such information
            to provide products or services to you, or to us, or to perform
            their duties. Some of the safeguards we use are firewalls and data
            encryption, physical access controls to our data centres, and
            information access authorization controls. To prevent unauthorised
            access to your information, we have implemented strong controls and
            security safeguards at the technical and operational levels.
          </p>
          <p>
            Our website uses a Secure Sockets Layer (SSL) to ensure secure
            transmission of your Personal Data. You should see the padlock
            symbol in your URL address bar when browsing through our website.
            The URL address will also start with https:// depicting a secure
            webpage. SSL applies encryption between two points such as your PC
            and the connecting server. Any data transmitted during the session
            will be encrypted before transmission and decrypted at the receiving
            end. This is to ensure that data cannot be read during transmission.
          </p>
          <p>
            We will never ask for your secure personal or account information by
            unsolicited means of communication. You are responsible for keeping
            your personal and account information secure and not sharing it with
            others. We shall not be liable for any unauthorised access or loss
            or personal information beyond our control.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">16. Retention period</h1>
          <p>
            Subject to the provisions of the NDPR 2019, the retention period for
            the personal information of users of Lifepadi shall be as follows:
          </p>
          <ul>
            <li>
              16.1. seven (7) years after the last active use of our digital
              platform;
            </li>
            <li>
              16.2. upon presentation of evidence of death by a deceased
              relative, personal information of such User would be discarded;
            </li>
            <li>
              16.3. immediately upon request by the User of Lifepadi or his/her
              legal guardian where:
              <ul>
                <li>a) no statutory provision states otherwise and</li>
                <li>
                  b) such User is not the subject of an investigation or suit
                  that may require retention of the personal information sought
                  to be deleted.
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">17. Direct marketing</h1>
          <p>
            We may occasionally contact you directly with related point-to-point
            restaurants, information, and special offers from us and other
            service providers. You consent to us sending you such information
            including by means of telemarketing, email, SMS and other digital
            channels when you "Subscribe" to receive marketing communications
            from Lifepadi.
          </p>
          <p>
            If at any time you decide you do not wish to receive marketing
            communications from us, you may click the “Unsubscribe” button in
            the marketing emails and messages or by emailing us. If you opt out
            of receiving marketing communications from us, we may still contact
            you or send you messages that are not of promotional nature, such as
            receipts for service provider's directory and delivery services
            booked through Lifepadi.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">
            18. Links to other websites
          </h1>
          <p>
            Our website may contain hyperlinks to websites or web addresses of
            third parties and third-party services that we do not operate. These
            hyperlinks are provided for your reference and convenience only and
            do not imply any endorsement of the activities of these third-party
            websites or any association with their operators. While we take
            appropriate care when publishing links to any third-party services,
            you shall, at your own discretion, decide whether to access such
            links or accept such services.
          </p>
          <p>
            Lifepadi gives no undertakings or warranties with respect to the
            accuracy, completeness, adequacy and reliability of any information,
            data, opinions, pictures, statements or suggestions provided by such
            links. Lifepadi does not have control over or assume any liability
            for third-party websites, their content or their data use and
            retention policies or practices. We urge you to review any privacy
            statement posted on any site you visit before using the site or
            providing any personal information about yourself and others.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">
            19. Access and corrections to personal information
          </h1>
          <p>
            19.1. Under the law, you have the right to access, correct, amend,
            and delete your personal information or object to processing your
            personal information.
          </p>
          <p>
            19.2. You may make any of the following requests in respect of your
            personal information within our custody:
          </p>
          <ul>
            <li>
              a) Confirmation on the personal information we hold about you;
            </li>
            <li>
              b) Access to the personal information we hold about you provided
              that such requests are made in compliance with the Freedom of
              Information Act and other applicable laws;
            </li>
            <li>
              c) Correction of or updates to the personal information we hold
              about you;
            </li>
            <li>
              d) Anonymization, blocking or erasure of your personal information
              that is no longer necessary in order for us to provide services to
              you or no longer necessary for our normal business operations and
              activities;
            </li>
            <li>
              e) Data portability. In some circumstances, you may, by express
              request, ask us to provide a third-party service provider with a
              copy of the personal information we hold about you in a
              structured, machine-readable, and commonly used format (as
              selected by us), or otherwise in accordance with the requirements
              of the applicable law;
            </li>
            <li>
              f) Deletion or erasure of personal information we hold about you,
              where we relied on your consent to use your personal information
              unless an exception applies, such as where we are required to
              retain such information to comply with our legal or regulatory
              obligations;
            </li>
            <li>
              g) Revoke your consent, where we rely on your consent to use and
              disclose your personal information.
            </li>
          </ul>
          <p>
            19.3. You can make any of the above requests by contacting us via
            email or filling out our Data Subject Request Form. We may ask you
            to provide suitable identification when you seek to make any of
            these requests, to verify your identity.
          </p>
          <p>
            19.4. We will provide you with a response as soon as possible. If we
            are unable to comply with your request for any reason (such as, not
            being permitted or required to do such an act under the privacy
            legislation in force), we will inform you of this.
          </p>
          <p>
            19.5. If the personal information we provide in response to your
            request includes information that is the personal information of a
            third party, or that is confidential, or in which we own the
            copyright (such as transcripts or recordings of customer service
            call records), we may redact such information where reasonably
            necessary, and you must keep such information confidential and not
            make the information public, including by sharing the information on
            social media, without our written consent sought and obtained.
          </p>
          <p>
            19.6. Users can also delete their accounts from the Lifepadi
            application. If you delete your account, we will retain your
            personal information in accordance with our Retention Policy.
          </p>
          <p>
            19.7. Lifepadi may retain your personal information after you have
            requested us to delete or de-identify your information if:
          </p>
          <ul>
            <li>
              a) There is an unresolved issue relating to your account, such as
              an outstanding credit on your account or an unresolved claim or
              dispute;
            </li>
            <li>b) We are required to by any applicable law; and/or</li>
            <li>
              c) Necessary for our legitimate business interests, such as fraud
              prevention and enhancing users’ safety and security.
            </li>
          </ul>
          <p>
            19.8. When personal information is no longer required, we will take
            reasonable steps to delete the personal information from our systems
            or de-identify the personal information.
          </p>
        </div>

        <div>
          <h1 className="text-2xl font-semibold mb-2">20. Governing law</h1>
          <p>
            This Notice is made pursuant to the NDPR 2019, NDPA 2023 and all
            applicable data protection laws and regulations, in Nigeria. Where
            any provision of this Notice is deemed inconsistent with a law,
            regulation or convention, such provision shall be subject to the
            overriding law, regulation or convention.
          </p>
          <p>
            This Notice shall be governed by the laws of the Federal Republic of
            Nigeria. This Notice applies to all Users of the Services provided
            by Lifepadi, and to anyone else who contacts Lifepadi or otherwise
            submits information to Lifepadi.
          </p>
          <p>Last reviewed in December, 2024</p>
        </div>
      </div>
    );
};

export default PrivacyPolicy;
