import React from "react";

const TermsAndCondition = () => {
  return (
    <div className="p-6 pt-20 flex flex-col gap-3 text-lg items-center bg-gray-100 text-gray-800">
      
          <h1 className="text-3xl font-bold pt-5">Terms and Conditions</h1>
      
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">1. General Terms</h2>
        <p>
          These Terms govern your use of our Platform. They explain the legally
          binding terms that apply to you when you access, browse, register, or
          otherwise use the Platform to enjoy our services. These Terms apply to
          all users of the Platform, including guests. By using our Platform in
          any way, you agree to these Terms. If you violate any of these Terms,
          we may restrict, suspend, or terminate your access to the Platform and
          our services. We may revise these Terms at any time by updating this
          page. Please review this page regularly to stay informed of any
          changes. We will notify existing users of any changes by sending a
          notification through the mobile application. If you do not agree to
          these Terms, please do not access or use the Platform.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">2. Services</h2>
        <p>
          Lifepadi allows you to request one of our Deckers to order or pick up
          food, groceries, over-the-counter drugs, medical supplies, and
          over-the-counter health products ("Pharmaceuticals") from your
          favorite nearby restaurants, supermarkets, and pharmacies
          (collectively, our "Services"). Our Platform allows you to choose from
          a variety of restaurants, supermarkets, and pharmacies to order your
          meals, groceries, and Pharmaceuticals. We will then pick up your order
          and deliver it to you. Lifepadi is not affiliated with any of the
          restaurants, supermarkets, or pharmacies listed on our Platform. We
          simply act as your agent to order and pick up your items. We do not
          own or have any control over the quality or safety of the food,
          groceries, or Pharmaceuticals offered by these businesses. We cannot
          guarantee the existence, quality, or safety of the food, groceries, or
          Pharmaceuticals offered on our Platform. We also do not guarantee the
          accuracy of the information provided by restaurants, supermarkets, or
          pharmacies. Finally, we cannot guarantee the safety of the
          Pharmaceuticals you order. Unlike our delivery services, we cannot
          guarantee that the meals, groceries, and Pharmaceuticals you order
          through our Platform will be of satisfactory quality. We disclaim all
          warranties, express or implied, to the fullest extent permitted by
          law.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">
          3. Accessing Our Platform and Services
        </h2>
        <p>
          We do not guarantee that our Platform or Services will always be
          available, uninterrupted, or error-free. We will not be liable to you
          if the Platform is unavailable for any reason. Our Platform may
          contain errors or omissions. You are responsible for ensuring that you
          have the necessary equipment and internet access to use our Platform.
          You are also responsible for ensuring that anyone who accesses the
          Platform through your account complies with these Terms.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">4. Creating an Account</h2>
        <p>
          To use our services, you must be 18 years or older and able to form
          legal contracts. By creating an account, you verify that you meet
          these requirements and that all information you provide is accurate
          and truthful.
        </p>
        <h3>Account Signup</h3>
        <p>
          To create an account, you will need to provide your full name, email
          address, phone number, and delivery address. Upon signup, you will
          receive a one-time password and be required to agree to our terms of
          use and privacy policy.
        </p>
        <h3>Keeping Your Account Secure</h3>
        <p>
          You are solely responsible for maintaining the confidentiality of your
          login information. This includes not sharing your password with
          anyone. You are also responsible for all activity that occurs under
          your account. If you suspect your password or account has been
          compromised, please contact us immediately at info@Lifepadi.com.
        </p>
        <h3>Using Your Account</h3>
        <p>
          Once you have an account, you can select restaurants, supermarkets,
          and pharmacies to order food, groceries, and Pharmaceuticals. A
          Lifepadi Champion will then pick up your order and deliver it to your
          registered address.
        </p>
        <h3>Account Termination</h3>
        <p>
          You can choose to terminate your account at any time. Once you do,
          your information will be deleted from our platform.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">5. Payment Options</h2>
        <p>
          Our platform uses a third-party payment processor to securely handle
          all transactions. We offer several payment options for your
          convenience:
        </p>
        <ul>
          <li>
            Closed-loop wallet: Top up your account wallet through available
            payment channels and use those funds to order food on our platform.
          </li>
          <li>Debit card</li>
          <li>Bank transfer</li>
          <li>QR code</li>
          <li>
            Other wallets or payment methods offered by our payment partners
          </li>
        </ul>
        <p>
          The payment processor is solely responsible for all payment
          transactions that occur on our platform.
        </p>
        <h3>Order Pricing</h3>
        <p>
          The prices for meals, groceries, and Pharmaceuticals are displayed in
          Nigerian Naira and include applicable taxes. The delivery fee will be
          shown at checkout.
        </p>
        <h3>Total Order Cost</h3>
        <p>
          The total price of your order, including food, groceries,
          Pharmaceuticals, and delivery fees, will be displayed at checkout and
          must be paid when you place your order. By creating an account, you
          agree to these payment terms.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">6. Intellectual Property</h2>
        <p>
          We and our partners own all intellectual property rights associated
          with our platform and the materials published on it. These rights are
          protected by Nigerian copyright laws. You may only access and use
          these materials for personal, non-commercial purposes. Reproducing,
          distributing, publicly displaying, or modifying these materials
          without our written permission is strictly prohibited.
        </p>
        <h3>Copyright Infringement</h3>
        <p>
          If you believe our platform content infringes on your copyright,
          please contact us at info@Lifepadi.com. We will take immediate steps
          to address the issue.
        </p>
        <h3>Food and Grocery Item Descriptions</h3>
        <p>
          Restaurant and supermarket menus and grocery item descriptions are
          provided by users who have requested us to purchase these items on
          their behalf. Lifepadi is not responsible for any inaccurate or
          inappropriate use of food or grocery item names on our platform. If
          you believe a description is incorrect, please contact us and we will
          take steps to fix it.
        </p>
        <h3>Pharmaceutical Descriptions</h3>
        <p>
          Pharmaceutical descriptions are provided by the listed pharmacies.
          Lifepadi is not responsible for any inaccurate or inappropriate use of
          Pharmaceutical descriptions on our platform. If you believe a
          description is incorrect, please contact us and we will take steps to
          fix it.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">7. No Reliance</h2>
        <p>
          The information on our Platform about meals, groceries, and
          Pharmaceuticals is for general knowledge only and should not be
          considered professional advice. While we strive to keep the
          information on our Platform up-to-date, we make no guarantees about
          its accuracy or completeness. You use this information at your own
          risk.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">8. Limitation of Liability</h2>
        <h3>General Disclaimer</h3>
        <p>
          We are not responsible for any losses or damages that may arise from
          your use of our Platform, except in cases of personal injury caused by
          our negligence, fraud, or other liabilities that cannot be legally
          excluded.
        </p>
        <h3>No Warranties</h3>
        <p>
          We disclaim all warranties, expressed or implied, regarding our
          Platform and its content.
        </p>
        <h3>Third-Party Websites</h3>
        <p>
          We are not responsible for the content of any websites linked to our
          Platform. These links do not imply our endorsement of those websites.
        </p>
        <h3>Commercial Use</h3>
        <p>
          Our Platform is intended for personal use only. You cannot use it for
          commercial or business purposes without our written consent. We are
          not liable for any lost profits or business opportunities resulting
          from unauthorized commercial use.
        </p>
        <h3>Damages</h3>
        <p>
          We are not liable for any indirect, punitive, incidental, special, or
          exemplary damages, including those resulting from your inability to
          use our services.
        </p>
        <h3>Account Security</h3>
        <p>
          We are not liable for any damages caused by unauthorized access to or
          use of your Lifepadi account.
        </p>
        <h3>Service Disruptions</h3>
        <p>We are not liable for any damages or losses resulting from:</p>
        <ul>
          <li>Delays or disruptions in our services, software, or platform.</li>
          <li>Your inability to use or access our services.</li>
          <li>Account suspensions or other actions taken by Lifepadi.</li>
          <li>
            Any errors, inaccuracies, or omissions in the data provided to us.
          </li>
          <li>
            Damage caused by viruses or other harmful software that may infect
            your devices due to your use of our Platform or linked websites.
          </li>
        </ul>
        <h3>General Limitation of Liability</h3>
        <p>
          We are not liable for any losses or damages, whether caused by
          contract, negligence, or any other legal theory, arising from your use
          or inability to use our Platform.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">9. Indemnification</h2>
        <p>
          You agree to indemnify and hold Lifepadi harmless from any claims,
          losses, or damages arising from:
        </p>
        <ul>
          <li>Your use or misuse of our services.</li>
          <li>Your use or misuse of any delivered Pharmaceuticals.</li>
          <li>Your violation of these Terms or any applicable laws.</li>
        </ul>
        <p>
          We reserve the right to assume the defense and control of any matter
          for which you are required to indemnify us, at your expense. You agree
          to cooperate fully with our defense of such claims.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">10. Term and Termination</h2>
        <p>
          These Terms are effective from the date you first use our services.
          You or Lifepadi may terminate these Terms at any time.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">11. Restricted Activities</h2>
        <p>
          You may only use our Platform for lawful purposes. You agree not to
          use our Platform:
          <ul>
            <li>In violation of any applicable laws or regulations.</li>
            <li>In any way that is unauthorized or detrimental to Lifepadi.</li>
            <li>For any fraudulent purpose.</li>
            <li>
              To modify, adapt, decipher, disassemble, or reverse engineer any
              of our platform's software.
            </li>
          </ul>
        </p>
      </div>

      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">12. Electronic Communications</h2>
        <p>
          By using our services, you consent to receiving electronic
          communications from us. This includes emails, notifications within the
          app, and other electronic messages. You agree that these electronic
          communications satisfy any legal requirements for written
          communications. You also agree to the use of electronic signatures,
          contracts, orders, and other records. You also agree to the electronic
          delivery of transaction records. You waive any rights or requirements
          that may require original signatures or the delivery or retention of
          non-electronic records.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">13. Severability</h2>
        <p>
          If any part of these Terms is found to be invalid, illegal, or
          unenforceable, that part will be separated from the rest of the Terms.
          The remaining Terms will continue to be fully enforceable to the
          extent permitted by law.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">14. Data Privacy</h2>
        <p>
          Lifepadi collects and processes your personal data. Please review our
          Privacy Policy for more information on how we use your data. Except
          for our payment processors, we do not share your personal information
          with any third parties.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">15. Breach of Terms</h2>
        <p>
          If you violate these Terms, applicable laws, or regulations, or engage
          in any fraudulent activity, we may take action. This may include:
        </p>
        <ul>
          <li>Suspending or terminating your access to our services.</li>
          <li>Reporting your actions to relevant authorities.</li>
          <li>Taking legal action.</li>
        </ul>
        <p>
          Any account suspension or termination will be effective immediately
          upon notification.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">16. Placing Orders</h2>
        <h3>Product Images</h3>
        <p>
          The images of products on our Platform may be stock photos and may not
          perfectly represent the actual product.
        </p>
        <h3>Order Process</h3>
        <p>
          You can place orders through our app. You can review and modify your
          order before confirming it.
        </p>
        <h3>Order Confirmation</h3>
        <p>
          Confirming your order creates an offer to purchase the selected items.
          You can only cancel your order before the restaurant or pharmacy
          accepts it.
        </p>
        <h3>Order Summary</h3>
        <p>
          You can view a summary of your past orders and payment history within
          the app.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">17. Order Delivery</h2>
        <h3>Delivery Agreement</h3>
        <p>You are responsible for receiving your orders.</p>
        <h3>Order Issues</h3>
        <p>
          If you do not receive all your ordered items, contact Lifepadi
          customer service immediately.
        </p>
        <h3>Order Refunds</h3>
        <p>
          You cannot request a refund or reschedule delivery for orders that you
          fail to receive.
        </p>
        <h3>Accurate Delivery Information</h3>
        <p>Ensure your delivery address and phone number are accurate.</p>
        <h3>Order Errors</h3>
        <p>
          If you receive an incorrect or damaged order, contact Lifepadi
          immediately. Return the order in its original condition.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">18. Submitting Complaints</h2>
        <p>
          If you have any complaints, you can contact Lifepadi customer service
          through our in-app chat box or by email at info@Lifepadi.com.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">19. Disclaimer</h2>
        <h3>Services Provided As Is</h3>
        <p>
          Our services are provided "as is" and "as available." We make no
          warranties or guarantees about our services, either express or
          implied.
        </p>
        <h3>No Relationship with Restaurants, Pharmacies, etc.</h3>
        <p>
          Lifepadi does not have any existing relationships or commitments with
          any restaurants, supermarkets, or pharmacies listed on our platform.
        </p>
        <h3>Service Availability</h3>
        <p>
          We make no guarantees about the reliability, timeliness, quality, or
          availability of our services. We also cannot guarantee that our
          services will be uninterrupted or error-free. You agree that you are
          solely responsible for any risks that arise from using our services.
        </p>
        <h3>Disclaimer for Pharmaceuticals</h3>
        <p>
          Lifepadi is not a medical professional. Our services are limited to
          delivering Pharmaceuticals. We do not provide medical advice,
          diagnoses, or any healthcare services.
        </p>
        <h3>Prescription Drugs</h3>
        <p>Our services are not available for prescription drugs.</p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">20. Promotional Codes</h2>
        <p>
          Lifepadi may offer promotional codes for discounts or other benefits.
          These codes are subject to any additional terms that we may establish
          for each code. Here are some rules for using promotional codes:
        </p>
        <ul>
          <li>Use the code for its intended purpose and in a lawful manner.</li>
          <li>
            Do not duplicate, sell, transfer, or make the code publicly
            available.
          </li>
          <li>
            The code may be disabled by Lifepadi at any time for any reason.
          </li>
          <li>
            The code can only be used according to the specific terms that we
            establish for it.
          </li>
          <li>Promotional codes cannot be redeemed for cash.</li>
          <li>Codes may expire before you use them.</li>
        </ul>
        <p>
          We reserve the right to withhold or deduct credits or benefits
          obtained through the use of promotional codes if we believe the code
          was used in error, fraudulently, illegally, or in violation of the
          terms.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">21. Governing Law</h2>
        <p>
          These terms of use are governed by Nigerian law. Any disputes
          regarding these terms will be settled in the Nigerian courts.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold">22. Version Control</h2>
        <p>
          These Terms of Use were last updated by Lifepadi Logistics Limited on
          December 31, 2024.
        </p>
      </div>
    </div>
  );
};

export default TermsAndCondition;
